import React from "react"
import { removeAuthToken } from '../../actions/_auth'

class AuthPage extends React.Component {

  componentDidMount() {
    removeAuthToken()
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.location = '/auth/signin'
  }

  render() {
    return null
  }

}

AuthPage.propTypes = {}
export default AuthPage